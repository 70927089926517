import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';

import SearchService from '../../../services/search.service';
import Layout from '../../../components/Layout/Layout';
import SuggestedProperty from '../../../components/SuggestedProperty/SuggestedProperty';
import ArrowIcon from '../../../assets/img/icons/ic-arrow-right-blue.svg';
import { getQueryParams } from '../../../helpers/helper-methods';

import styles from './NothingFound.module.scss';

const NothingFound = ({ location }) => {
  const [suggestions, setSuggestions] = useState([]);
  const searchService = useMemo(() => SearchService(), []);
  const { search } = location;

  const getSuggestions = async (requestId) => {
    const { data } = await searchService.getBestOffersByUUID(requestId);

    setSuggestions(data);
  };

  useEffect(() => {
    const { requestId } = getQueryParams(search);

    getSuggestions(requestId);
  }, []);

  return (
    <Layout mainClasses="column-flexed-wrapper">
      <div className="blue-grad-bg flex flex-grow-1 flex-h-start">
        <div className={`${styles.page} container m-auto`}>
          <div className="row">
            <div className="col">
              <p className={styles.header}>
                There is
                {' '}
                <span className="t-">no matching Offer</span>
                {' '}
                for your search inputs.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 col-lg-6">
              <p className={styles.text}>
                We suggest you amend some of the selected options and filters and/or
                expand your search criteria
              </p>
            </div>
            <div className="col-12">
              <Link to="/" className={styles.link}>
                <ArrowIcon />
                Back to Search
              </Link>
            </div>
          </div>

          {!!suggestions.length && (
            <>
              <div className="row">
                <div className={`col-12 col-lg-3 ${styles.offers}`}>
                  <p className="t-700">
                    {`Check top ${suggestions.length} offers`}
                  </p>
                  <p>(all options + no filters)</p>
                </div>
              </div>

              <div className="row">
                {suggestions.map(({ property, PV }, index) => (
                  <div className="col-12 col-md-6 col-lg-4" key={index}>
                    <SuggestedProperty PV={PV} index={index} {...property} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NothingFound;
