import React from 'react';
import { navigate } from 'gatsby';
// import BookmarkIcon from '../../assets/img/icons/ic_bookmark_default.svg';
import PlaceholderImg from '../../assets/img/placeholder.svg';

import styles from './SuggestedProperty.module.scss';

const SuggestedProperty = ({
  title, PV, photos, index, id,
}) => (
  <div className={styles.card} onClick={() => navigate(`/results-shared/?propertyId=${id}&detailsView=true`)}>
    {/* <BookmarkIcon className={styles.bookmark} /> */}
    <div className={styles.cardImage}>
      {photos?.[0]?.file?.link
        ? <img src={photos?.[0]?.file?.link} className={styles.img} alt="property" />
        : <img src={PlaceholderImg} alt="placeholder" className={styles.ph} />}
    </div>
    <div className={`flex flex-between ${styles.cardTextWrapper}`}>
      <div className={`${styles.cardTitle} text-truncate`}>
        {title}
      </div>
      <div className={styles.cardPoints}>
        {`#${index + 1} PV : ${PV?.toFixed(2)}`}
      </div>
    </div>
  </div>
);

export default SuggestedProperty;
